import '../../css/home/index.scss';

$(function() {

    let _hash, _scrollTop;
    $('.nav-link[href*="#"]').click(function(e) {
        _hash = $(this).attr('href');
        _hash = _hash.substr(_hash.indexOf('#'));
        _scrollTop = $(hash).offset().top;
        setTimeout(() => {
            TweenMax.to('html, body', .6, { scrollTop: _scrollTop, ease: Quart.easeOut });
        }, 400);
    });

    if ($(window).width() > 768) {

        var winH = $(window).height();

        TweenMax.to('.c1', 12, { x: '120%', y: '-50%', yoyo: true, repeat: -1, ease: Power1.easeInOut })
        TweenMax.to('.c2', 9, { x: '-120%', y: '50%', yoyo: true, repeat: -1, ease: Power1.easeInOut })
        TweenMax.to('.c3', 10, { x: '120%', y: '-50%', yoyo: true, repeat: -1, ease: Power1.easeInOut })

        TweenMax.to('.c4', 10, { x: '-120%', y: '-60%', yoyo: true, repeat: -1, ease: Power1.easeInOut })
        TweenMax.to('.c5', 15, { x: '120%', y: '60%', yoyo: true, repeat: -1, ease: Power1.easeInOut })
        TweenMax.to('.c6', 9, { x: '120%', y: '60%', yoyo: true, repeat: -1, ease: Power1.easeInOut })

        TweenMax.staggerFrom('.whiten', .4, { opacity: 0, scale: 0, ease: Back.easeOut }, .15, function() {
            $('.b11').addClass('active');
        })
        TweenMax.staggerFrom('.label_box', .4, { opacity: 0, y: -winH / 2, delay: .2, ease: Back.easeOut.config(1.4) }, .15)

    }

    //swiper ------------------------------------------
    if ($(".kv_swiper").length > 0) {

        //kv_swiper
        const $swiper = '.kv_swiper';
        const container = $swiper + ' .swiper';
        const pagination = $swiper + ' .swiper-pagination';
        const button_next = $swiper + ' .swiper-button-next';
        const button_prev = $swiper + ' .swiper-button-prev';

        new Swiper(container, {
            grabCursor: true,
            loop: true,
            loopedSlides: 1,
            speed: 300,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: pagination,
                clickable: true,
            },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
        });

    }
    if ($(".app_swiper").length > 0) {

        //kv_swiper
        const $swiper = '.app_swiper';
        const container = $swiper + ' .swiper';
        const pagination = $swiper + ' .swiper-pagination';
        const button_next = $swiper + ' .swiper-button-next';
        const button_prev = $swiper + ' .swiper-button-prev';

        new Swiper(container, {
            grabCursor: true,
            loop: true,
            loopedSlides: 1,
            speed: 600,
            // pagination: {
            //     el: pagination,
            //     clickable: true,
            // },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
        });

    }
    if ($(".m_map_swiper").length > 0) {

        //kv_swiper
        const $swiper = '.m_map_swiper';
        const container = $swiper + ' .swiper';
        const pagination = $swiper + ' .swiper-pagination';
        const button_next = $swiper + ' .swiper-button-next';
        const button_prev = $swiper + ' .swiper-button-prev';

        new Swiper(container, {
            grabCursor: true,
            slidesPerView: 1,
            // loop: true,
            // loopedSlides: 1,
            speed: 600,
            pagination: {
                el: pagination,
                type: 'fraction',
                clickable: true,
            },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
        });

    }
    if ($(".intro_swiper").length > 0) {

        //kv_swiper
        const $swiper = '.intro_swiper';
        const container = $swiper + ' .swiper';
        const pagination = $swiper + ' .swiper-pagination';
        const button_next = $swiper + ' .swiper-button-next';
        const button_prev = $swiper + ' .swiper-button-prev';

        new Swiper(container, {
            grabCursor: true,
            slidesPerView: 2,
            speed: 600,
            pagination: {
                el: pagination,
                type: 'fraction',
                clickable: true,
            },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.05,
                },
                480: {
                    slidesPerView: 2,
                },
            }
        });

    }


});